@font-face {
  font-family: "Lexend";
  src: url("./assets/Lexend-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/Lexend-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/Lexend-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

html {
  --background-color: #121212;
  --text-color: #ffffff;
  --primary-color: #34c759;
  --error-color: #ff3b30;
  --border-color: #333333;
  --button-hover-color: #a37e2c;

  /* Combine both backgrounds using multiple background syntax */
  background: 
    /* Pattern comes first */ linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.02) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.02) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.02) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.02) 75%),
    /* Your original gradient comes last */
      linear-gradient(to bottom, #000000, #1d3338);

  /* Add these properties for the pattern */
  background-size: 20px 20px, /* Pattern size */ 20px 20px, 20px 20px, 20px 20px,
    100% 100%; /* Gradient size */

  background-position: 0 0, 0 10px, 10px -10px, -10px 0px, 0 0; /* Gradient position */

  /* Optional: Add the animation */

  color: var(--text-color);
  font-family: "Lexend", sans-serif;
  scroll-behavior: smooth;
}

body {
  font-family: "Lexend", sans-serif;
  min-height: 100vh;
}

/*break points */

/* styles.css or your main CSS file */
.subheader {
  opacity: 0; /* Start invisible */
  transition: opacity 1s; /* Smooth transition */
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 1.8rem;
}

.subheader.visible {
  opacity: 1; /* Fade in when 'visible' class is added */
}

.main-header {
  font-weight: 700;
  font-size: 4rem;
}

.header-text {
  font-size: 20px;
  margin: 40px auto;
  width: 70%;
}

.header-subtext {
  font-style: italic;
  margin: auto;
  width: 30%;
}

.iceberg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e6f1ff;
  position: relative;
  max-width: 800px;
  margin: auto;
  margin-bottom: 30px;
}

.autowatch-depth-finder {
  position: relative;
  top: -600px;
  right: 290px;
  background-color: rgba(26, 32, 44, 0.8);
  border-radius: 10px;
  padding: 20px;
  max-width: 250px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 10;
  margin: auto;
  margin-bottom: -200px;
}

.autowatch-decoder {
  position: absolute;
  bottom: -60px;
  left: 20px;
  background-color: rgba(26, 32, 44, 0.8);
  border-radius: 10px;
  padding: 20px;
  max-width: 250px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 2;
}

.autowatch-depth-finder p {
  font-size: 14px;
}

.header-subtext2 {
  margin: auto;
  width: 70%;
  margin-bottom: 50px;
  font-size: 1.6rem;
}

.header-subtext3 {
  margin: auto;
  width: 70%;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 1.7rem;
}

/* Default (desktop view) */
.goto-engine-button {
  position: absolute;
  right: 0;
  top: 20px;
  height: 40px;
  border-radius: 10px;
  background-color: #48515d;
  z-index: 999;
  margin-right: 0.7em;
  color: white; /* Button text color */
  font-size: 1em; /* Adjust as needed */
  padding: 0.5em 1em;
}

.goto-engine-button::after {
  content: "Go to App →";
}

/* Default (desktop view) */
.goto-account-button {
  position: absolute;
  right: 0;
  top: 80px;
  height: 40px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #fff;
  z-index: 999;
  margin-right: 0.7em;
  color: white; /* Button text color */
  font-size: 1em; /* Adjust as needed */
}

.goto-account-button::after {
  content: "Account";
}

.chat-button {
  position: absolute;
  top: 80px;
  left: 0;
  margin-left: 0.7em;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.chat-button::after {
  content: "Chat";
}

/* Default (desktop view) */
.autowatch-button {
  position: absolute;
  left: 0;
  top: 20px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: transparent;
  z-index: 999;
  margin-left: 0.7em;
  color: white; /* Button text color */
  font-size: 1em; /* Adjust as needed */
  padding: 0.5em 1em;
}

.autowatch-button::after {
  content: "About";
}

.container {
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
}

.container h1 {
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.modal-content-sessions {
  background: #2e2e2e;
  padding: 25px;
  border-radius: 8px;
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  color: #e0e0e0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  font-family: "Roboto", sans-serif;
}

.modal-sessions-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.2s ease;
}

.modal-sessions-close:hover {
  color: #ff5f5f;
}

.sessions-list {
  margin-top: 20px;
}

.session-item {
  background: #3a3a3a;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: background 0.2s ease;
}

.session-item:hover {
  background: #4a4a4a;
}

.session-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
}

.session-id {
  font-weight: bold;
}

.session-status {
  background: #555;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.session-details {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.6;
}

.session-duration,
.session-started {
  margin-bottom: 5px;
}

.total-matches-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background: rgba(0, 127, 127, 0.85);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.2s ease;
}

.total-matches-btn:hover {
  background: #ff7f7f;
}

/* ====================== */
/*    MODAL WRAPPER       */
/* ====================== */

/* If you need base styles for your modal wrapper/content, include them here.
   Adjust these to match your existing code or design. */
.tutorial-modal-wrapper {
  position: fixed;
  top: 20px; /* or bottom: 20px if you prefer */
  right: 10px;
  width: 90vw; /* replaces 'width: 90%' plus makes it responsive in viewport width */
  max-width: 500px;

  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  overflow: hidden;
  transition: all 0.3s ease;
}

.tutorial-modal-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.tutorial-modal-wrapper.minimized {
  width: 200px;
  height: 40px;
  opacity: 0.8;
  visibility: visible;
  cursor: pointer;
}

/* The container for the inner content of the modal */
.tutorial-modal-content {
  padding: 1rem;
  position: relative;
}

/* Close button inside the modal */
.tutorial-modal-close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #999;
  font-size: 1.2rem;
  cursor: pointer;
}
.tutorial-modal-close-button:hover {
  color: #666;
}

.wpfb-donut-container {
  font-family: sans-serif;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.wpfb-donut {
  position: relative;
}

.wpfb-donut-inner {
  position: absolute;
  top: 0;
  left: 0;
}

/* Placeholder ring for Facebook (subtle dashed guide) */
.wpfb-placeholder-ring {
  position: absolute;
  top: 0;
  left: 0;
  border: 1.5px dashed rgba(24, 119, 242, 0.5); /* Transparent Facebook blue */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  pointer-events: none;
}

/* Legend Styling */
.wpfb-legend {
  margin-top: 8px;
  display: flex;
  gap: 10px;
}

.wpfb-legend-item {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.wpfb-legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 2px;
}

.wpfb-whatsapp {
  background: #25d366;
}

.wpfb-facebook {
  background: #1877f2;
}

/* ====================== */
/*  TOGGLE BUTTON STYLES  */
/* ====================== */

.help-toggle-button {
  margin: 1rem auto;
  display: block;
  background-color: #387771;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
}
.help-toggle-button:hover {
  background-color: #2b5c5a;
}

/* ====================== */
/*  TUTORIAL SECTION CSS  */
/* ====================== */

.tutorial-visual-container {
  max-width: 500px; /* Adjust as needed, e.g., 500px, 600px */
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  color: #485563;
}

.tutorial-visual-title {
  margin-bottom: 1rem;
  font-size: 1rem; /* Slightly smaller title */
}

/* Create a grid for the steps */
.tutorial-visual-steps {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-items: center;
}

/* On larger screens, go to a 3-column layout */
@media (min-width: 600px) {
  .tutorial-visual-steps {
    grid-template-columns: repeat(3, 1fr);
  }
}

.tutorial-visual-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorial-visual-icon {
  font-size: 1.5rem; /* Smaller icon size */
  margin-bottom: 0.25rem;
}

.tutorial-visual-text h4 {
  margin: 0;
  font-size: 0.9rem; /* Smaller heading */
  color: #2f4454;
}

.tutorial-visual-text p {
  margin: 0;
  font-size: 0.8rem; /* Smaller paragraph text */
  color: #485563;
}

.tutorial-visual-explore {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #387771;
}

/* ================================ */
/*  EXPAND/COLLAPSE ANIMATION CSS  */
/* ================================ */

/* By default, both sections are collapsed. Adjust overflow, max-height, and opacity. */
.tutorial-info-section,
.ticket-help-section {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

.tutorial-info-section.expanded {
  max-height: 600px; /* Increase if the content is taller */
  opacity: 1;
}

.ticket-help-section.expanded {
  max-height: 1000px; /* Increase if the content is taller */
  opacity: 1;
}

/* Optional if you need any spacing or margins for the help section */
.ticket-help-section {
  margin-top: 1rem;
}

/* Example styling for your ticket list, etc. */
.tickets-list {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.ticket-item {
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.ticket-message {
  font-size: 0.9rem;
}

.ticket-status {
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.ticket-status.Awaiting {
  color: #f39c12; /* orange-ish for waiting? */
}

.ticket-status.Answered {
  color: #2ecc71; /* green-ish for answered? */
}

.ticket-response {
  font-size: 0.8rem;
  margin-top: 0.25rem;
  color: #333;
}

.ticket-date {
  font-size: 0.75rem;
  color: #777;
  margin-top: 0.25rem;
}

/* The ticket form area */
.tutorial-modal-ticket {
  margin-top: 1.5rem;
}

.ticket-header {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: #485563;
}

.ticket-comment-box {
  width: 100%;
  min-height: 80px;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.5rem;
  font-family: inherit;
  resize: vertical;
}

.ticket-submit-button {
  background-color: #387771;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
  outline: none;
}
.ticket-submit-button:hover {
  background-color: #2b5c5a;
}

.fade-in-delay-logo-container {
  opacity: 0; /* Start transparent */
  animation: fadeInLogoContainer 0.7s ease forwards;
  animation-delay: 0.7s; /* Wait 2 seconds before starting the fade */
}

@keyframes fadeInLogoContainer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo-container {
  position: relative;
  display: inline-block;
  width: 170px; /* Match your original logo size */
  height: 170px; /* Ensure the aspect ratio matches */
  min-width: 170px; /* Prevent shrinking */
  min-height: 170px; /* Prevent shrinking */
}

.logo-container img {
  display: block;
}

.eye-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure it fills the container */
  height: 100%;
  animation: glowAnimation 10s ease-in-out infinite;
}

.hour-hand-logo,
.minute-hand-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%; /* Rotate around the center */
  transform: translate(-50%, -50%);
  width: 90%; /* Scale clock hands */
  height: auto;
  display: block;
}

.hour-hand-logo {
  animation: rotate-hour-logo 60s infinite linear;
}

.minute-hand-logo {
  animation: rotate-minute-logo 30s infinite linear;
}

@keyframes rotate-hour-logo {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-minute-logo {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes glowAnimation {
  0% {
    filter: drop-shadow(
      0 0 10px rgba(0, 153, 255, 0.45)
    ); /* Start with a smaller, fainter glow */
  }
  50% {
    filter: drop-shadow(
      0 0 20px rgba(0, 153, 255, 0.88)
    ); /* Peak with a stronger, larger glow */
  }
  100% {
    filter: drop-shadow(
      0 0 10px rgba(0, 153, 255, 0.45)
    ); /* Return to the original state */
  }
}

.orb-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 90%;
  margin: 20px auto;
}

.orb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-align: center;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.database-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  background-color: #1c1c1e; /* 'white' can be used as well */
  max-width: 800px;
  margin: auto;
}

.total-matches-watchlist {
  margin: auto;
  z-index: 10;
  font-weight: 700;
  color: rgb(6, 255, 255);
  font-size: 2rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  cursor: pointer;
}

.grey {
  color: grey !important;
}

.btn {
  padding: 10px 20px; /* Larger buttons for prominence */
  font-size: 1.2rem; /* Larger font size */
  margin: 5px; /* Spacing around buttons */
  border: none; /* Removing border */
  transition: transform 0.2s; /* Smooth transformation on click */
}

.btn.active {
  transform: scale(1.1); /* Slightly enlarges the active button */
}

/* Next button */
.next-btn {
  background-color: #1c1c1e; /* Green color for next button */
  color: #ffffff; /* Light text for readability */
  font-size: 1rem; /* Standard font size */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding for larger clickable area */
  border: 1px solid rgba(128, 128, 128, 0.123);
  position: absolute;
  top: 27%;
  right: 19%;
  transform: translate(-50%, -50%);
}

/* Next button */
.back-btn-left {
  background-color: #1c1c1e; /* Green color for next button */
  color: #ffffff; /* Light text for readability */
  font-size: 1rem; /* Standard font size */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding for larger clickable area */
  border: 1px solid rgba(128, 128, 128, 0.123);
  position: absolute;
  top: 15%;
  left: 25%;
  transform: translate(-50%, -50%);
}

/* Divider between Buy and Sell buttons */
.or-divider {
  margin: 10px 15px; /* More space around the divider */
  color: #ffffff; /* Light color for dark mode */
  font-weight: bold; /* Bold for emphasis */
  align-self: center;
  font-size: 1.2rem;
}

h1 {
  margin-bottom: 1rem;
  color: #333;
}

.input-container {
  display: flex;
  justify-content: space-evenly; /* Change alignment to space elements evenly */
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: row;
  margin: auto;
  gap: 15px;
}

.input-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-label-bottom {
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 0.5rem;
}

.arrow {
  font-size: 2rem;
  margin: 0 1rem;
}
.input-container input {
  margin-right: 1.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
  margin: auto;
}

.input-container .group {
  margin-right: 1.5rem;
}

.input-container input:focus {
  border-color: #007bff;
}

.buy-sell-selection {
  display: flex;
  margin-right: 0.5rem;
}

.buy,
.sell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 2.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #888;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.buy:hover,
.sell:hover {
  transform: translateY(-5px);
}

.buy.active {
  background-color: #34c759;
  color: #fff;
}

.sell.active {
  background-color: #ff3b30;
  color: #fff;
}

.selection-indicator {
  margin-left: 0.5rem;
  color: #ffffff;
  font-size: 0.8rem;
}

.keyword-list {
  list-style-type: none;
  padding: 0;
}

.keyword-list li {
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Add this line to distribute content evenly */
  margin-bottom: 0.5rem;
  background-color: #1c1c1e; /* Slightly lighter than the container for contrast */
  color: var(--text-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.keyword-list li .keyword {
  flex-grow: 1;
  margin-left: 0.5rem;
  color: #333;
}

.tag {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 0.5rem; /* Add margin to separate the tag from the keyword */
  color: var(--text-color);
}

.buy-tag {
  background-color: #34c759;
  color: #fff;
  margin: 10px;
}

.sell-tag {
  background-color: #ff3b30;
  color: #fff;
  margin: 10px;
}

.main-button {
  background: linear-gradient(45deg, rgb(37 105 110), rgb(41 56 59));
  transition: background 0.3s ease; /* Ensures smooth transition */
}

.main-button:hover {
  background: linear-gradient(
    45deg,
    rgb(60 140 145),
    rgb(55 70 75)
  ); /* A different gradient on hover */
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: #2e3a3b;
  color: var(--text-color);
}

button:hover {
  background-color: var(--button-hover-color);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.top-buttons-container {
  top: 0;
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.top-buttons-container.hidden {
  opacity: 0;
  top: 20px;
  pointer-events: none;
}
.start-btn,
.stop-btn {
  position: absolute;
  right: 45px;
  top: 5px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: 1px solid #666;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;
  height: 23px;
  letter-spacing: 5px;
  font-style: italic;
  animation: pulse 100s infinite;
}

.start-btn {
  animation: pulse 6s infinite;
}

.pause-btn {
  animation: pulse 3s infinite;
}

.input-container input:disabled {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-allowed;
}

button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* App.css */

.tag-style {
  position: absolute;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.67em;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .tag-style {
    font-size: 0.65em;
    padding: 2px 6px;
  }
}

@media (max-width: 480px) {
  .tag-style {
    font-size: 0.55em;
    padding: 2px 4px;
  }
}

.price-lead-style {
  --bg-color: rgba(0, 255, 0, 0.2);
  --border-color: #00ff00;
  --text-color: #00ff00;

  background-color: var(--bg-color);
  padding: 0.4em 0.8em;
  border-radius: 15px;
  margin-bottom: 0.3em;
  border: 1px solid var(--border-color);
  font-weight: 600;
  font-size: 1.1em;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .price-lead-style {
    font-size: 0.9em; /* Reduce font size on smaller screens */
    padding: 0.3em 0.6em; /* Adjust padding */
    border-radius: 10px; /* Slightly smaller border-radius */
  }
}

@media (max-width: 480px) {
  .price-lead-style {
    font-size: 0.8em; /* Even smaller font for very small screens */
    padding: 0.2em 0.5em;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-content {
  background-color: #1e2a2a;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  width: 65vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.modal-message {
  margin-bottom: 1rem;
}

.modal-close-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: #eec8c819;
  color: #fff;
}

.watch-leads-display h3 {
  text-align: center;
  margin-bottom: 20px;
}

.watch-leads-display {
  overflow-y: auto;
  flex-grow: 1; /* Allow this section to take available space */
}

@keyframes move-border {
  0%,
  100% {
    clip-path: inset(0 0 100% 0); /* Start at the bottom left */
  }
  25% {
    clip-path: inset(0 0 0 100%); /* Move to top left */
  }
  50% {
    clip-path: inset(100% 0 0 0); /* Move to top right */
  }
  75% {
    clip-path: inset(0 100% 0 0); /* Move to bottom right */
  }
}

@keyframes inflate-text1 {
  0%,
  100% {
    transform: scale(1.05); /* Text is at normal size */
  }

  70%,
  80% {
    /* Slightly before and after 75% to create a smooth effect */
    transform: scale(
      1
    ); /* Text inflates when the border is at the bottom right */
  }
}

@keyframes inflate-text2 {
  0%,
  100% {
    transform: scale(1); /* Text is at normal size */
  }

  70%,
  80% {
    /* Slightly before and after 75% to create a smooth effect */
    transform: scale(
      1.05
    ); /* Text inflates when the border is at the bottom right */
  }
}

.group {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 40px;
  display: inline-block;
  position: relative;
}

.group::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #937018;
  border-radius: 5px;
  z-index: -1;
  animation: move-border 3s linear infinite;
}

.group2 {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  overflow: auto; /* or 'scroll' if you always want scrollbars */
  position: relative;
}

.group2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #937018;
  border-radius: 5px;
  z-index: -1;
  animation: move-border 30s ease-in-out 1s infinite alternate both;
}

.group3 {
  position: relative;
}

.group3::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #f4c24e;
  border-radius: 5px;
  z-index: 9995;
  animation: move-border 8s ease-in-out 1s infinite alternate both;
}

.label-top {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: var(
    --background-color
  ); /* to ensure the text is readable */
  padding: 0 10px;
  font-weight: bold;
  font-size: 23px;
  animation: inflate-text1 6s linear infinite;
}

.label-bottom {
  position: absolute;
  bottom: -8px;
  right: 10px;
  background-color: var(
    --background-color
  ); /* to ensure the text is readable */
  padding: 0 10px;
  font-weight: bold;
  font-size: 23px;
  animation: inflate-text2 6s linear infinite;
}

.spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #ccc;
  border-top: 2px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.searching-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 2s linear infinite;
}

.searching-text {
  font-size: 20px;
  margin-left: 10px;
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  16.67% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.searching-icon-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searching-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 4px;
  opacity: 0;
  animation: dot-animation 1.5s ease-in-out infinite;
}

.searching-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.searching-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.searching-dot:nth-child(4) {
  animation-delay: 0.6s;
}

.searching-dot:nth-child(5) {
  animation-delay: 0.8s;
}

.searching-dot:nth-child(6) {
  animation-delay: 1s;
}

.add-to-watchlist {
  margin-top: 2vh;
}
hr {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 0;
  border: none;
  border-top: 1px solid rgba(128, 128, 128, 0.123);
  max-width: 800px;
}

.action-buttons-container {
  border-top: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000000;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-direction: column;
  height: 120px;
  transition: all 0.7s ease-in-out;
  padding: 20px 0; /* Add padding to maintain spacing */
}

.watchlist-content {
  width: 100%;
  opacity: 1;
  transform: scale(1) translateY(0);
  transform-origin: bottom center;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
}

.minimized-container {
  height: 50px !important;
}

.minimized-container .watchlist-content {
  opacity: 1;

  transform: scale(0.58) translateY(18%);
}

/* Pseudo-element to handle the background and hue rotation */
.action-buttons-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #000000,
    #0a1112,
    #031317,
    #04100d,
    #090d11,
    #0b0d11,
    #0f0b04,
    #000000
  );
  background-size: 400% 400%;
  z-index: -1; /* Ensure the background stays behind the content */
  transition: filter 2s ease;
}

/* When animated, apply the hue-rotate only to the background layer */
.action-buttons-container.animated::before {
  animation: hueAnimation 60s linear infinite;
}

@keyframes hueAnimation {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

.bubble-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.bubble {
  position: absolute;
  bottom: -20px; /* Start slightly below the bottom */
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 0;
  animation: rise 6s ease-in infinite, float 4s ease-in-out infinite;
}

/* Randomize the starting position and animation timings for more natural bubbles */
.bubble:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.5);
}

.bubble:nth-child(3n) {
  width: 15px;
  height: 15px;
}

.bubble:nth-child(4n) {
  animation-duration: 8s, 6s;
}

/* Set random horizontal position for each bubble to spread them across the width */
.bubble {
  left: calc(
    100% * var(--x-position)
  ); /* This will spread the bubbles across the width */
}

/* Rising animation to move bubbles from the bottom to the top */
@keyframes rise {
  0% {
    bottom: 0;
    opacity: 0;
  }
  10% {
    opacity: 1; /* Bubble fades in */
  }
  100% {
    bottom: 100%; /* Move to the top of the container */
    opacity: 0; /* Bubble fades out */
  }
}

/* Slight horizontal float animation to make it feel more natural */
@keyframes float {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.watchlist-container {
  display: flex;
  justify-content: center; /* Change back to flex-start to allow margin animation */
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
  width: 100%;
  padding: 0 20px;
  transition: all 0.3s ease-in-out, margin-left 1.2s ease-in-out; /* Add specific transition for margin-left */
}

.watch-slot {
  width: 60px;
  height: 60px;
  border: 1.5px dashed #ccc;
  background-color: #1c1c1c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
}

.watch-slot.hidden {
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}

.locked-slot.hidden {
  opacity: 0;
  transform: scale(0);
}

.watchlist-container.centered {
  justify-content: flex-start; /* Keep aligned to the left */
}

.watchlist-title {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-top: 7px;
}

.watchlist-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.core-ref {
  position: absolute;
  top: calc(100% + 5px);
  color: white;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: 10;
}

.remove-button {
  position: absolute;
  bottom: 90%;
  left: 72%;
  background-color: #ff3b302e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: darkred;
}

.edit-filter-button {
  position: absolute;
  top: 70%;
  right: 80%;
  background-color: #133b55d9;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
}

.edit-filter-button:hover {
  background-color: darkblue;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 0;
  filter: drop-shadow(
    0 0 8px rgba(0, 255, 204, 0.29)
  ); /* Slightly stronger glow for active image */
}

.carousel-image.active {
  opacity: 1;
  z-index: 1;
  transform: scale(1.05);
  filter: drop-shadow(
    0 0 10px rgba(0, 255, 204, 0.32)
  ); /* Slightly stronger glow for active image */
}
.watch-slot:hover {
  transform: scale(1.1); /* Slightly enlarge the slot on hover */
}

.filled-slot {
  font-size: 18px;
  color: #fff;
  background-color: #333;
  padding: 5px;
  text-align: center;
  border-radius: 8px;
}

.empty-slot {
  font-size: 24px;
  color: #999;

  cursor: pointer;
}

@keyframes subtleShake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

.shake {
  animation: subtleShake 0.5s ease-in-out;
  animation-delay: 0.3s; /* Wait for minimize/expand to finish */
}

.toggle-btn.toggle-btn-shake {
  animation: subtleShake 0.5s ease-in-out;
  animation-delay: 0.3s; /* Wait for minimize/expand to finish */
}

.toggle-btn {
  position: absolute;
  right: 12px;
  top: 5px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: 1px solid #666;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.65rem;
}

.toggle-btn-sessions {
  position: absolute;
  left: 12px;
  top: 5px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  background: transparent;
  border: 1px solid #666;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.65rem;
}

.matches-this-session {
  position: absolute;
  left: 4px;
  z-index: 2;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.6rem;
  bottom: 14px;
}

.toggle-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Minimized container tweaks */
.minimized-container {
  height: 40px;
  padding: 5px;
  justify-content: flex-end; /* Keep the toggle button visible */
}

/* Adjustments for button and instructions inside the container */
.action-buttons-container .start-btn,
.action-buttons-container .stop-btn,
.action-buttons-container .instructions {
  transition: opacity 2s ease; /* Smooth transition for opacity */
}

/* When the container is minimized, affect the text inside */
.minimized-container .instructions {
  opacity: 0; /* Fade text to invisible */
}

.watchlist-info {
  margin-top: 1.5vh;
  margin-bottom: 2vh;
  justify-content: center;
  display: flex;
}

.instructions {
  margin-left: 50px;
  max-width: 50%;
  height: 2vh;
  font-size: 1.2rem;
  color: white;
}

@keyframes fade-in {
  0% {
    opacity: 0.5;
    color: #95c8ff;
  }
  100% {
    opacity: 1;
  }
}

.animated-lead {
  /* Start invisible, smaller, and off to the left */
  opacity: 0;
  transform: translateX(-20px) scale(0.9);

  /* Play the popInFromLeft animation over 0.6 seconds, once only */
  animation: popInFromLeft 0.6s ease forwards;
}

@keyframes popInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px) scale(0.9); /* Start slightly left and smaller */
  }
  50% {
    opacity: 0.7;
    transform: translateX(5px) scale(1.05); /* Slightly overshoot for a pop effect */
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1); /* Settle in the final position */
  }
}
.watching-text {
  animation: fade-in 10s ease-in-out infinite alternate;
  font-size: 3rem;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.network-strength-bar {
  width: 150px; /* Adjust the width to fit your desired size */
  height: 10px; /* Adjust the height to fit your desired size */
  background-color: lightgray; /* Background color of the bar */
  margin: auto;

  /* Optional: Add additional styles to customize the appearance */
}

.strength-bar {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.red {
  background-color: red;
}

.chart-container {
  width: 30vw;

  margin: auto;
}

.chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bar {
  margin-right: 5px;
  width: 2px;
  background-color: #007bff;
  position: relative;
  transition: height 0.3s ease;
}

.time-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  margin-left: -3px;
}

.time {
  font-size: 0.5rem;
  text-align: center;
  width: 2px;
  color: #555;
}

.color-selector {
  text-align: center;
}
@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adjust .brand-tile for responsiveness */
.brand-tile-parent {
  flex: 1 1 calc(25% - 20px); /* 4 items per row, subtracting gap */
  max-width: calc(25% - 20px); /* Ensure tiles don’t exceed row space */
  box-sizing: border-box; /* Prevent padding from affecting width */
}

.brand-tile {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0; /* Initial opacity for animation */
  animation: drop-in 0.5s ease forwards; /* Run animation when rendered */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effects */
.brand-tile-parent:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
}

.brand-logo {
  width: 50%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
}

.brand-name {
  font-size: 0.9em;
  text-align: center;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease; /* Smooth color transition */
}

.brand-tile:hover .brand-name {
  color: #937018; /* Gold color on hover */
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .brand-tile-parent {
    flex: 1 1 calc(33.33% - 20px); /* 3 items per row */
    max-width: calc(33.33% - 20px);
  }
}

@media (max-width: 768px) {
  .brand-tile-parent {
    flex: 1 1 calc(50% - 20px); /* 2 items per row */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .brand-tile-parent {
    flex: 1 1 100%; /* 1 item per row */
    max-width: 100%;
  }
}
.brand-search-input {
  margin-top: 63px;
  display: flex;
}

.main-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px; /* Adjusted width for better visibility */
  height: 100px; /* Adjusted height to accommodate logo */
  margin: 20px;
  background-color: #2e2e2e; /* Dark background for contrast */
  color: #ffffff; /* White text for contrast */
  font-size: 1em; /* Larger font size */
  font-weight: bold; /* Bold font weight */
  text-shadow: 1px 1px 2px black; /* Text shadow for legibility */
  border: 2px solid #e7e7e7; /* Subtle border for definition */
  border-radius: 12px; /* Rounded corners for a modern look */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  overflow: hidden; /* Ensures content fits within the tile */
}

.main-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* More pronounced shadow on hover */
}

.main-tile-description {
  font-size: 1.1em; /* Larger font for main description */
  color: #333; /* Darker text for emphasis */
  margin-top: 5px; /* Adjusted spacing */
}

.main-tile-subdescription {
  font-size: 0.9em; /* Adjusted font size for sub-description */
  color: #969696; /* Dark gray for subtle emphasis */
  margin-top: 5px; /* Adjusted spacing */
  font-style: italic;
}

.specify-exact-tile {
  background-color: #0056b3; /* Deep blue */
  color: #ffffff; /* White text for contrast */
  border-radius: 12px; /* Consistent rounded corners */
  padding: 10px 20px; /* Appropriate padding */
  font-size: 1em; /* Larger font size */
  font-weight: bold; /* Bold font weight */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Text shadow for legibility */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.specify-exact-tile:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.custom-search-tile {
  background-color: #234923; /* Light green */
  color: #ffffff; /* White text for contrast */
  border-radius: 12px; /* Consistent rounded corners */
  padding: 10px 20px; /* Appropriate padding */
  font-size: 1em; /* Larger font size */
  font-weight: bold; /* Bold font weight */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Text shadow for legibility */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.custom-search-tile:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-3px); /* Slight lift on hover */
}

.watchlist-highlight {
  display: inline-block;
  background: #53461b42; /* Softer color or use a gradient */
  color: #fff; /* Text color */
  padding: 1rem 2rem; /* Padding around the text */
  border-radius: 15px; /* Subtler curve */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  font-size: 1rem; /* Text size */
  margin: 1rem auto; /* Vertical space and horizontally centered */
  text-align: center;
  max-width: 80%; /* Maximum width of the highlight */
}

.watchlist-highlight-button {
  display: inline-block;
  background: #505050; /* Softer color or use a gradient */
  color: #fff; /* Text color */
  padding: 1rem 2rem; /* Padding around the text */
  border-radius: 15px; /* Subtler curve */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  font-size: 1.2rem; /* Text size */
  margin: 1rem auto; /* Vertical space and horizontally centered */
  text-align: center;
  max-width: 80%; /* Maximum width of the highlight */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 17vw;
  text-decoration: none;
}

.watchlist-highlight-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.watchlist-highlight-button-special {
  display: inline-block;
  background: #937018; /* Softer color or use a gradient */
  color: #fff; /* Text color */
  padding: 1rem 2rem; /* Padding around the text */
  border-radius: 15px; /* Subtler curve */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  font-size: 1.2rem; /* Text size */
  margin: 1rem auto; /* Vertical space and horizontally centered */
  text-align: center;
  max-width: 80%; /* Maximum width of the highlight */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 17vw;
  text-decoration: none;
}

.watchlist-highlight-button-special:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.task-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; /* Space between icon and text */
  width: 22px; /* or the size you prefer */
  height: auto; /* to maintain aspect ratio */
  fill: white;
}

@keyframes subtle-pulse {
  0%,
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    transform: scale(1.1) translate(-50%, -50%);
  }
}
@keyframes soft-gold-flash {
  0%,
  100% {
    border-color: initial;
  }
  50% {
    border-color: rgba(
      192,
      170,
      45,
      0.7
    ); /* Softer gold color with some transparency */
  }
}

.leads-search-button {
  height: 50%;
  align-self: end;
}

.flash-and-pulse {
  animation: subtle-pulse 1s ease-out both, soft-gold-flash 1s ease-out both;
}

.filter-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: auto;
  width: 65vw;
  margin-bottom: 3vh;
}

.table-container {
  overflow-y: auto;
  border: 1px solid #444;
  background-color: #000;
  color: #fff;
  height: 90%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #444;
}

th,
td {
  padding: 8px;
  text-align: left;
  /* max-height: 50px;
  height: 50px; */
}

th {
  background-color: #222;
}

tr:nth-child(even) {
  background-color: #111;
}

tr:hover {
  background-color: #333;
}

.loader {
  border-radius: 70px;
  border: 3px solid #fff;
  position: relative;

  display: block;
  width: 120px;
  height: 120px;
}

.loader:after {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 13px;
  left: 48%;
  height: 50px;
  width: 4px;
  border-radius: 5px;
  -webkit-transform-origin: 50% 97%;
  transform-origin: 50% 97%;
  -webkit-animation: grdAiguille 1s linear infinite;
  animation: grdAiguille 1s linear infinite;
}

@-webkit-keyframes grdAiguille {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes grdAiguille {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 24px;
  left: 48%;
  height: 40px;
  width: 4px;
  border-radius: 5px;
  -webkit-transform-origin: 50% 94%;
  transform-origin: 50% 94%;
  -webkit-animation: ptAiguille 6s linear infinite;
  animation: ptAiguille 6s linear infinite;
}

@-webkit-keyframes ptAiguille {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes ptAiguille {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  position: absolute;
  top: calc(
    10% + 50px
  ); /* Adjusts dynamically based on the height of the loader-animation */
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  animation: typing 3s steps(30, end) infinite,
    blink-caret 0.75s step-end infinite;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  text-align: center;
  overflow: hidden;
  position: relative;
  height: 500px;
}

.loader-text-extra {
  font-size: 20px;
  color: #ffffff;
  opacity: 0.8;
  white-space: nowrap;
  animation: pulse 8s infinite;
  padding: 0 20px; /* Increase padding to prevent clipping */
  margin-top: 25px;
}

/* Pulse animation */
@keyframes pulse {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05); /* Slightly increase the size for pulsing effect */
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 35ch;
  }
  100% {
    width: 0;
  }
}

@keyframes blink-caret {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

.center-watch-small {
  width: auto;
  height: 200px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 60vw;
  margin: auto;
}

.center-container input {
  font-size: 1.1rem;
  max-width: 35vw;
}

.input-container-suggestions {
  position: relative; /* Establishes a new positioning context */
  width: 100%;
}

.reference-search-input {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
}

.reference-search-input::placeholder {
  color: #bbb;
}

.suggestion-header {
  color: #ddd;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 900;
  text-decoration: underline;
}

.missing-watch-option {
  color: #ddd;
  font-size: 16px;
  font-weight: 900;
  text-decoration: underline;
}

.suggestion-dropdown {
  z-index: 9999;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  font-size: 14px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  box-sizing: border-box;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 45vh;
  overflow-y: auto;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  color: #ccc;
  border-bottom: 1px solid #444;
}

.suggestion-item:hover {
  background-color: #444;
  color: #fff;
}

/* "Missing Watch?" Styles */

.missing-watch-container {
  margin-top: 10px;
  background-color: #444;
  padding: 15px;
  border-radius: 5px;
}

.missing-watch-header {
  color: #fff;
  font-size: 16px;
  margin-bottom: 8px;
}

.missing-watch-form {
  display: flex;
  flex-direction: column;
}

/* Container Styling */
.reference-input-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
}

/* Label Styling */
.reference-label {
  font-size: 0.6rem;

  color: #ffffff;
  width: 100%;
  margin-bottom: 12px;
}

/* Input Styling */
.reference-search-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .reference-input-container {
    max-width: 600px;
  }

  .reference-search-input {
    font-size: 1.1rem;
  }
}

.missing-watch-input {
  padding: 8px;
  border: 1px solid #555;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #555;
  color: #fff;
  margin: auto;
  max-width: 20vw !important;
  font-size: 0.9rem !important;
  margin-bottom: 12px;
}

.missing-watch-input::placeholder {
  color: #ccc;
}

.missing-watch-button {
  padding: 10px;
  background-color: #016d6d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.missing-watch-button:disabled {
  background-color: #128c7e;
  cursor: not-allowed;
}

.missing-watch-button:hover:not(:disabled) {
  background-color: #1da851;
}

.submit-status {
  margin-top: 8px;
  font-size: 14px;
}

.submit-status.success {
  color: #4caf50;
}

.submit-status.error {
  color: #f44336;
}

/* Media Queries for Mobile */

@media (max-width: 768px) {
  .center-container input {
    max-width: 90vw;
  }
  .reference-search-input::placeholder {
    font-size: 12px; /* Smaller placeholder text */
  }

  .suggestion-header {
    font-size: 14px;
  }

  .suggestion-dropdown {
    font-size: 14px;
  }

  .missing-watch-header {
    font-size: 14px;
  }

  .missing-watch-input {
    font-size: 14px;
  }

  .missing-watch-button {
    font-size: 14px;
  }
}

.brand-tiles-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 10px; /* Space between tiles */
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  justify-content: center; /* Center the tiles */
  width: 90%; /* Use percentage for responsive width */
  max-width: 1200px; /* Add a maximum width for larger screens */
}

.references-display {
  display: flex;
  flex-direction: column; /* Align children in a vertical column */
  gap: 10px; /* Space between tiles */
  padding: 10px;
  margin-top: 10px;
}

.reference-item {
  background: #f5f5f5; /* Light grey background */
  padding: 10px; /* Padding inside each reference item */
  border-radius: 4px; /* Rounded corners for each item */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  color: #333; /* Darker text for better readability */
  margin: 5px 0; /* Margin top and bottom for spacing */
}

.quality-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.dashboard-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.stat-title {
  font-size: 16px;
  color: white;
}

.stat-value {
  font-size: 20px;
  font-weight: bold;
}

.message-container {
  width: 60%; /* Narrower message box */
  margin-bottom: 20px;
}

.editable-message {
  width: 100%;
  height: 150px; /* Adjustable based on preference */
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Lexend", sans-serif;
}

.input-placeholder::placeholder {
  color: red;
  opacity: 1; /* Firefox lowers the opacity by default */
}

.input-filled::placeholder {
  color: grey; /* Or any color that indicates the field is not empty */
}

.references-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  background-color: #1c1c1c; /* Adjusted to a darker shade */
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.references-grid.show {
  opacity: 1;
  transform: translateY(0);
}

.reference-tile {
  border: 1px solid #555; /* Lighter border for contrast */
  border-radius: 10px;
  background-color: #252525; /* Adjusted to a darker shade */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Added shadow for depth */
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.reference-tile:hover {
  transform: translateY(-5px); /* Hover effect */
}

.watch-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjust for spacing */
  height: 100%;
}

.watch-detail {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.watch-detail h3 {
  margin-bottom: 10px;
  color: #333;
}

.watch-detail span {
  display: block;
  font-size: 14px;
  color: #666;
}

.info-icon-circle {
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
  color: #007bff !important;
  cursor: pointer;
  border: 1px solid #007bff;
  border-radius: 40%;
  padding: 2px 6px;
  position: relative;
  width: 7px;
  margin: auto;
}

.info-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.info-icon:hover::before,
.info-icon-circle:hover::before,
.info-button:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.info-icon:hover::before,
.info-icon-circle:hover::before,
.info-button:hover::before {
  opacity: 1;
  visibility: visible;
}

.info-icon:hover::after,
.info-icon-circle:hover::after,
.info-button:hover::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.info-icon:hover::after,
.info-icon-circle:hover::after,
.info-button:hover::after {
  opacity: 1;
  visibility: visible;
}

.interest-level-info {
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
}

.interest-level-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
  align-content: center;
  width: 33%;
  margin-top: 2%;
  margin-bottom: 4%;
}

.interest-level-info strong {
  color: #333;
}

.watch-detail-left {
  background-color: rgba(255, 255, 255, 0.05); /* More subtle background */
  border: none; /* Remove borders for a cleaner look */
  color: #fff; /* White text for contrast */
  margin: 5px 0; /* Consistent spacing */
  padding: 8px;
  border-radius: 5px;
  text-align: left; /* Center text */
  font-family: "Lexend", "Arial", sans-serif; /* Sleek, modern font */
  font-size: 0.9rem; /* Adjust font size as necessary */
}

.watch-image {
  max-width: 65%; /* Adjust image size */
  max-height: auto;
  object-fit: contain;
  margin-top: 15px; /* Space above the image */
}
.watch-image-small {
  max-height: 130px; /* Adjust image size */
  max-width: auto;
  object-fit: contain;
  margin-top: 15px; /* Space above the image */
}

.loader {
  color: #fff; /* White text for loader and no results message */
  text-align: center;
}

.watch-detail p {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

/* Add any additional specific styling as needed */

.brand-box {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.watching-text {
  position: relative; /* Ensures the container is positioned relatively */
  display: inline-block; /* Keeps the inline behavior of the h1 element */
}

.letter-container {
  position: relative; /* Container for the letters */
  height: 1em; /* Set to the height of your text, adjust as necessary */
  width: 1em; /* Set to the width of your largest letter, adjust as necessary */
  display: inline-block; /* Allows the container to fit the size of the letters */
}

.letter {
  position: absolute; /* Positions both letters absolutely within their container */
  top: -7%;
  right: 1%;
  transition: transform 0.8s ease, opacity 0.8s ease;
  transform-origin: center center; /* Adjust based on the visual effect you're aiming for */
  opacity: 1; /* Start with fully visible */
}

.hide {
  opacity: 0; /* Fades out the letter */
  transform: rotateX(180deg); /* Flips the letter */
}

.watch-text,
.letter-container {
  display: inline-block;
}

.hide {
  transform: rotateX(180deg); /* Rotates the letter vertically */
  opacity: 0; /* Fades out the letter */
}

/* Example dot animation */
@keyframes dotPulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.matches-container {
  max-height: 600px;
  overflow-y: auto; /* Vertical scrolling for the entire matches container */
  background-color: #3a3a3c; /* Slightly lighter dark background for matches container */
  border: 1px solid #555; /* Darker border for contrast */
  padding: 10px;
  margin-bottom: 20px;
}

.match-item {
  margin-bottom: 20px;
}

.parsed-watches-container {
  display: flex; /* Makes the child elements (watch-details) align horizontally */
  overflow-x: auto; /* Enables horizontal scrolling */
  background-color: #48484a; /* Dark background for parsed watches */
  border: 1px solid #666; /* Border for contrast */
  padding: 10px;
}

.watch-details {
  flex: 0 0 auto; /* Prevents watch details from shrinking, ensuring horizontal scrolling */
  background-color: #525252; /* Even darker background for each watch detail */
  margin-right: 10px; /* Space between each watch detail */
  padding: 10px;
  border-radius: 4px; /* Optional: rounded corners for each watch detail */
}

/* Additional styling for scrollbar to fit dark theme */
.matches-container::-webkit-scrollbar,
.parsed-watches-container::-webkit-scrollbar {
  width: 6px;
  height: 6px; /* Adjust scrollbar thickness */
}

.matches-container::-webkit-scrollbar-thumb,
.parsed-watches-container::-webkit-scrollbar-thumb {
  background-color: #6b6b6b; /* Scrollbar color */
  border-radius: 3px;
}

.matches-container::-webkit-scrollbar-track,
.parsed-watches-container::-webkit-scrollbar-track {
  background-color: #2c2c2e; /* Scrollbar track color */
}

.contact-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  font-size: 0.8rem;
}

.contact-info img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-info p {
  margin: 0;
  margin-right: 10px;
}

.contact-info button {
  padding: 5px 10px;
  background-color: #25d366; /* WhatsApp green color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.alert-container {
  position: fixed;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 999999;
}

/* Base alert styling */
.alert {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1); /* Optional subtle border */
  padding: 10px 20px;
  color: white;
  display: flex;
  align-items: center;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  width: auto;
  text-align: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateX(50px); /* Initial animation state */
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.alert-detailed {
  max-width: 600px; /* Wider width for detailed alerts */
  width: auto; /* Keep it responsive */
}

.alert-lead-count {
  position: absolute;
  right: 8px;
  top: 140px;
  width: 120px;
  font-size: 15px;
}

.alert-show {
  opacity: 1;
  transform: translateX(0); /* Final animation state */
}

.alert-dismiss {
  opacity: 0;
  transform: translateX(50px); /* Dismiss animation state */
}

.alert-info {
  background-color: #1d3338; /* Deep Rolex Green */
  color: #ffffff; /* White for text contrast */
}

.alert-success {
  background-color: #217c5f; /* Muted, sophisticated green */
  color: #ffffff; /* White for text contrast */
}

.alert-warning {
  background-color: #d4a017; /* Rolex Gold */
  color: #000000; /* Black for text contrast */
}

.alert-error {
  background-color: #7b202a; /* Deepened red for a rich, luxurious tone */
  color: #ffffff; /* White for text contrast */
}

/* Tooltip specific styling */
.alert-tooltip {
  background-color: rgba(0, 0, 0, 0.85);
  font-size: 37px;
  padding: 5px 10px;
  border-radius: 4px;
  position: relative;
  opacity: 0;
  transform: translateY(0); /* No horizontal movement for tooltips */
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.alert-tooltip.alert-show {
  opacity: 1;
}

.alert-tooltip.alert-dismiss {
  opacity: 0;
}

/* Tooltip arrow */
.alert-tooltip::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Position below the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

/* Close button for regular alerts */
.close-button-alert {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: -20px;
}

.chatWindow {
  position: fixed;
  top: 80px;
  left: 20px;
  width: 300px;
  height: 600px;

  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 9998;
}

.pricePulseTracker {
  position: fixed;
  top: 180px;
  left: 20px;
  width: 300px;
  height: 600px;

  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 9996;
}

/* ScratchPad.css */
.scratchPad {
  position: fixed;
  top: 40px;
  left: 20px;
  width: 300px;
  height: 200px;

  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.tickTock {
  position: fixed;
  font-size: 13px;
  text-decoration: underline;
  top: 0px;
  left: 27px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  color: "white";
}

.leadNest {
  position: fixed;
  top: 120px;
  left: 20px;
  width: 300px;
  height: 200px;

  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 9997;
}

.scratchPad textarea {
  flex-grow: 1;
  margin-top: 10px;
  resize: none;
  color: black;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.controls button {
  padding: 0 10px;
}

.custom-toggle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #2d2d2d;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  width: 10vw;
}

.toggle-description {
  margin-bottom: 10px;
}

.toggle-detail {
  font-size: 14px;
  color: #ccc;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  align-self: center;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.references-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.family-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  width: 77vh;
}

.variant-card {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 8px 0;
  padding: 8px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}

.variant-card.fade-out {
  opacity: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.watchcontainer-button {
  font-size: 12px;
}

.tabs-container {
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 20px;
}

.tab-button {
  flex: 1;
  padding: 12px 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button:hover {
  background-color: #f5f5f5;
  color: teal;
}

/* Hide all the icon elements (fire-icon, limited-icon, stale-icon, feed-icon) by default inside the tab buttons */
.tab-button .fire-icon,
.tab-button .limited-icon,
.tab-button .stale-icon,
.tab-button .feed-icon {
  opacity: 0;
  transform: translateY(20px); /* Adjust for a small slide-down effect */
  transition: opacity 0.4s ease, transform 0.4s ease;
}

/* Show the icons only when the button is active or hovered */
.tab-button.active .fire-icon,
.tab-button.active .limited-icon,
.tab-button.active .stale-icon,
.tab-button.active .feed-icon,
.tab-button:hover .fire-icon,
.tab-button:hover .limited-icon,
.tab-button:hover .stale-icon,
.tab-button:hover .feed-icon {
  opacity: 1;
  transform: translateY(0); /* Move back into view smoothly */
}

.tab-button.active {
  border-bottom: 4px solid #51969f;
  font-weight: bold;
  color: #51969f;
}

/* Add transition to tab content */
.tab-content {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.tab-content > div {
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#glowEffect {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: 20px;
  background: radial-gradient(
    circle,
    rgba(164, 140, 61, 0.8) 0%,
    rgba(164, 140, 61, 0) 70%
  );
  opacity: 0.5;
  filter: blur(10px);
  z-index: 1;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    opacity: 0.5;
    transform: translateX(-50%) scale(1);
  }
  to {
    opacity: 0.8;
    transform: translateX(-50%) scale(1.2);
  }
}

.lead-feed-placeholder {
  text-align: center;
  padding: 50px 0;
  color: #666;
}
.experimental-tag {
  background-color: #ffeb3b; /* Soft yellow */
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  color: #333;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.8rem;
  margin-top: 10px;
}

.disclaimer {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

.tab {
  position: relative;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  margin-bottom: -1px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: none;
  border-radius: 10px 0 0 0;
  background: #0e0d0d;
  transition: all 0.3s ease;
  overflow: hidden;

  max-width: 60px;
  height: 40px;
  white-space: nowrap;
}

.tab:hover {
  max-width: 200px; /* Expand on hover */
}

.tab .tab-match-count {
  display: block;
  text-align: center;
}

.tab .tab-buy-intent {
  display: none; /* Hide ref initially */
  font-size: 0.8em;
}

.tab:hover .tab-buy-intent {
  display: block; /* Show ref on hover */
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px gold;
  }
  100% {
    box-shadow: none;
  }
}

/* Active tab styling */
.active-tab {
  animation: glow 5s ease-out forwards; /* Use the animation */
}

/* This styles the scrollbar track */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  height: 10px; /* Adjust the height of the scrollbar for horizontal scroll */
  background-color: #0e0d0d; /* Dark background */
}

/* This styles the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #bdaa59; /* Goldish color */
  border-radius: 5px;
}

/* This styles the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4af37; /* Slightly lighter gold color on hover */
}

/* This styles the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #0e0d0d;
}

/* For Firefox */
html {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #bdaa59 #0e0d0d; /* thumb and track color */
}

.year-month-selector {
  margin-bottom: 20px;
}

.year-section,
.month-section {
  margin-bottom: 20px;
}

.year-list,
.month-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.year-button,
.month-button {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #444;
  color: #fff;
  transition: background-color 0.3s;
}

.year-button.selected,
.month-button.selected {
  background-color: #008cba;
}

.year-button:hover,
.month-button:hover {
  background-color: #555;
}

.family-header {
  text-align: center;
  margin-bottom: 20px;
}

.brand-box {
  font-size: 1.4rem;
  font-weight: 700;
  color: #2c3e50;
}

.watch-detail h3 {
  font-size: 1.1rem;
  color: #34495e;
  margin: 5px 0;
}

.watch-detail span {
  font-size: 0.9rem;
  color: #7f8c8d;
}

.variants-wrapper {
  position: relative;

  width: 62%; /* Ensure it takes up the full width */
  overflow: hidden; /* Prevent overflow during transitions */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.family-variants {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px; /* Adjust spacing */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  will-change: opacity;
}

.family-variants.fade-out {
  opacity: 0;
  transform: translateY(10px); /* Add a subtle movement effect */
}
.center-watch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.watch-image {
  max-width: 80%;
  height: auto;
  border-radius: 5px;
}

.price-box {
  margin-top: 10px;
}

.price-text {
  font-size: 1rem;
  font-weight: 600;
  color: #27ae60;
}

.dial-color {
  background-color: #ecf0f1;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 0.8rem;
  color: #2c3e50;
  margin-top: 5px;
}

.watch-detail {
  font-size: 1.3rem;
  color: #042224;
}

.search-leads-button {
  margin-top: 2%;
  margin-bottom: 1%;
  background-color: #374655;
}

.price-summary {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    #161a23,
    #0e140c
  ); /* Change background to white for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.price-summary h3 {
  margin-bottom: 10px;
  font-size: 1.4em;
  color: #333; /* Darker text for readability */
  text-align: center; /* Center align the heading */
  font-weight: 600; /* Slightly bolder for emphasis */
}

.price-summary p {
  margin-bottom: 10px;
  font-size: 1em;
  color: #38aaaa; /* Darker text for readability */
  text-align: center; /* Center align the heading */
  font-weight: 300; /* Slightly bolder for emphasis */
}

.price-summary ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.price-summary li {
  display: flex; /* Use flex for horizontal alignment */
  justify-content: space-between; /* Space between text and price */
  align-items: center; /* Vertically center align */
  padding: 10px 0;
  font-size: 1.2em;
  color: #555; /* Medium-dark text color for readability */
  border-bottom: 1px solid #eee; /* Subtle bottom border */
}

.price-summary li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.price-summary li strong {
  color: #007bff; /* Use a primary color for color labels */
  font-weight: 500;
}

.reference-search-input {
  background-color: #51969f24;
  color: #fff;
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
}

.input-container-suggestions {
  position: relative; /* Establishes a new positioning context */
}

.suggestion-dropdown {
  z-index: 9999;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  font-size: 26px;
  width: 95%;
  margin-left: 1%;
  position: absolute;
}

.suggestion-header {
  color: #ddd;
  margin-bottom: 5px;
  font-size: 20px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
  color: #ccc;
  border-bottom: 1px solid #444;
}

.suggestion-item:hover {
  background-color: #444;
}

.or-separator {
  padding: 5px;
  text-align: center;
  color: #888;
  font-style: italic;
  font-size: 20px;
}

.direct-input-option {
  padding: 5px;
  cursor: pointer;
  color: #ccc;
}

.direct-input-option:hover {
  background-color: #444;
}

.custom-tooltip[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px; /* Adjust the font size here */
  white-space: nowrap;
  z-index: 10;
}

.activity-report-header {
  border: 1px black solid;
  border-radius: 5%;
  margin-left: auto;
  margin-right: auto;
  padding: 7px;
  background-color: #393434;
  color: white;
}

.watches-container.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.watches-container.hide {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
}

.watches-container {
  margin-bottom: 10%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden; /* Ensure it's hidden when not visible */
  padding: 20px;
}

.watches-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.watch-rank {
  font-size: 1.2rem;
  font-weight: bold;
  color: #a37e2c; /* Gold color */
  margin-bottom: 5px;
  margin: -15px 0 -5px 0;
}

.analyze-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #006039; /* Use your theme's primary color */
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.analyze-button:hover {
  background-color: var(
    --button-hover-color
  ); /* Slightly darker shade for hover effect */
}

.watch-card {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Faint white outline */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: rgba(26, 32, 44, 0.33); /* Dark background */
  color: #ffffff; /* White text */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  align-content: center;
  padding: 20px;
  margin: 5px;
  flex: 0 0 calc(20%);
}

.watch-card.show {
  opacity: 1;
  transform: translateY(0);
}

.watch-card:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.watch-card img {
  max-width: 100%;
  max-height: 200px; /* Limit the height to avoid overflow issues */
  object-fit: contain; /* Ensures the image fits well within the card */
  border-radius: 8px;
}

/* Lead Feed Icon Styles */
.feed-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #4413b4; /* Primary Color */
  overflow: hidden;
  top: 8px;
  right: 8px;
}

.feed-line {
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: #4cc9f0; /* Accent Color */
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  animation: feed 2s infinite;
}

.feed-line:nth-child(2) {
  animation-delay: 0.5s;
}

.feed-line:nth-child(3) {
  animation-delay: 1s;
}

@keyframes feed {
  0% {
    transform: translateY(-50%) translateX(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-50%) translateX(100%);
    opacity: 0;
  }
}

/* Limited Edition Icon Styles */
.limited-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  top: 14px;
}

.star-feed {
  position: absolute;
  width: 70%;
  height: 70%;
  background: #c2b062; /* Accent Color */
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  animation: rotate 4s infinite linear;
}

.star-feed:nth-child(2) {
  transform: scale(0.8);
  opacity: 0.8;
  animation-direction: reverse;
  background: #ee438e; /* Secondary Color */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* Stale Listings Icon Styles */
.stale-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  top: 12px;
  right: 4px;
}

.clock {
  position: relative;
  width: 70%;
  height: 70%;
  border: 3px solid #3e5f51; /* Text Color */
  border-radius: 50%;
}

.hand-stale {
  position: absolute;
  background: #3e5f51; /* Text Color */
  transform-origin: bottom center;
  border-radius: 2px;
}

.hour-hand-stale {
  width: 2px;
  height: 30%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate-hour 12s linear infinite;
}

.minute-hand-stale {
  width: 2px;
  height: 40%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate-minute 1.5s linear infinite;
}

.dust {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #4cc9f0; /* Accent Color */
  border-radius: 50%;
  opacity: 0;
  animation: dust 4s infinite;
}

.dust:nth-child(2) {
  animation-delay: 1s;
  left: 25%;
  top: 25%;
}

.dust:nth-child(3) {
  animation-delay: 2s;
  right: 25%;
  bottom: 25%;
}

@keyframes rotate-hour {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes rotate-minute {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes dust {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px) rotate(45deg);
    opacity: 1;
  }
}

.fire-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 45px;
  transform-origin: bottom center;
  animation: sway 1s infinite alternate ease-in-out;
}

.flame {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 12px;
  height: 21px;
  background: radial-gradient(ellipse at bottom, #ff4500, transparent 70%);
  border-radius: 50%;
  animation: flicker 0.4s infinite alternate ease-in-out;
  transform: translateX(-50%);
  filter: blur(1px);
  opacity: 0.9;
}

.flame:nth-child(2) {
  width: 18px;
  height: 30px;
  background: radial-gradient(ellipse at bottom, #ff6347, transparent 70%);
  animation-delay: 0.2s;
  opacity: 0.8;
}

.flame:nth-child(3) {
  width: 15px;
  height: 27px;
  background: radial-gradient(ellipse at bottom, #ffa500, transparent 70%);
  animation-delay: 0.3s;
  opacity: 0.7;
}

@keyframes flicker {
  0% {
    transform: translateX(-50%) scaleY(1);
    opacity: 0.9;
  }
  100% {
    transform: translateX(-50%) scaleY(1.4);
    opacity: 0.7;
  }
}

@keyframes sway {
  0% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

.animated-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 200px; /* Set a fixed height to ensure visibility */
}

.animated-carousel-smaller {
  position: relative;
  overflow: hidden;
  width: 80%;
  height: 90px; /* Set a fixed height to ensure visibility */
  margin: auto;
}

.carousel-image {
  width: 100%;
  height: 100%; /* Ensure the image fills the container */
  object-fit: contain; /* Maintain aspect ratio without cropping */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
}

.carousel-image:nth-child(1) {
  opacity: 1;
  z-index: 1;
}

.pagination-buttons {
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin: auto;
}

.pagination-controls,
.modal-close-btn {
  margin-top: 15px;
}

.pagination-buttons button {
  background-color: transparent; /* Remove background color */
  border: none; /* Remove borders */
  font-size: 2.5rem; /* Increase arrow size */
  color: black; /* Change arrow color */
  cursor: pointer;
  z-index: 3;
}

.pagination-buttons button:disabled {
  opacity: 0.5; /* Reduce opacity when disabled */
  cursor: not-allowed;
}

.ticket-history {
  margin-top: 20px;
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  color: teal;
}

.tickets-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ticket-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.ticket-message {
  font-size: 1em;
  color: #333;
}

.ticket-status {
  font-size: 0.9em;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.ticket-status::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: currentColor;
}

.ticket-status.Awaiting::before {
  background-color: orange;
}

.ticket-status.Answered::before {
  background-color: green;
}

.ticket-response {
  background-color: #e8f5e9;
  padding: 10px;
  border-left: 4px solid #4caf50;
  margin-top: 10px;
  border-radius: 3px;
}

.ticket-date {
  font-size: 0.8em;
  color: #555;
  margin-top: 5px;
}

/* Ticket Filter */
.ticket-filter {
  margin-top: 15px;
  margin-bottom: 10px;
}

.ticket-filter label {
  font-size: 0.9em;
  color: #333;
}

/* Support Ticket Form */
.tutorial-modal-ticket {
  margin-top: 20px;
}

.ticket-header {
  margin-bottom: 10px;
  color: #333;
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-left: 4px solid #d32f2f;
  margin-bottom: 10px;
  border-radius: 3px;
}

.info-message {
  color: #333;
  background-color: #e7f3fe;
  padding: 10px;
  border-left: 4px solid #2196f3;
  margin-bottom: 10px;
  border-radius: 3px;
}

.ticket-comment-box {
  width: 100%;
  height: 80px;
  padding: 10px;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: 0.95em;
  margin-bottom: 10px;
}

.ticket-comment-box:disabled {
  background-color: #f5f5f5;
}

.ticket-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s;
}

.ticket-submit-button:disabled {
  background-color: #90caf9;
  cursor: not-allowed;
}

.ticket-submit-button:hover:not(:disabled) {
  background-color: #1976d2;
}

.tutorial-modal-minimized-button p {
  margin: 0;
  font-size: 1em;
}

.ticket-message {
  font-size: 1em;
}

.ticket-response {
  background-color: #e8f5e9;
  padding: 10px;
  border-left: 4px solid #4caf50;
  margin-top: 10px;
  border-radius: 3px;
}

.ticket-date {
  font-size: 0.8em;
  color: #555;
  margin-top: 5px;
}

.lead-image-thumbnail {
  width: 100px; /* Set the desired thumbnail size */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Change cursor to indicate it's clickable */
}

.lead-image-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.lead-image-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.lead-image-modal-image {
  max-width: 90vw;
  max-height: 80vh;
}

.lead-image-close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #333;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.pagination-info {
  text-align: center;
  margin: 10px 0;
  font-size: 1rem;
  color: #333;
}

.filter-console h4 {
  margin: 0 0 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.filter-console {
  background-color: #141b1f;
  border-radius: 10px;
  margin-bottom: 20px;
  z-index: 1;
  margin-top: -10px;
}

.filter-console-grid {
  display: grid;
  height: 20px;
  gap: 5px;
}

.filter-section {
  background-color: #294641;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.filter-section h4 {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #fff;
  border-bottom: 1px solid #776e6e;
  padding-bottom: 5px;
}

.color-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color-filters label {
  display: inline-block;
  font-size: 0.9rem;
  margin-right: 10px;
  cursor: pointer;
  background-color: #555;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.color-filters label:hover {
  background-color: #5a83da;
}

.box-paper-filters,
.condition-filter,
.dated-filter,
.serial-filter {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.box-paper-filters label,
.condition-filter label,
.dated-filter label,
.serial-filter label {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #555;
  padding: 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.box-paper-filters label:hover,
.condition-filter label:hover,
.dated-filter label:hover,
.serial-filter label:hover {
  background-color: #666;
}

input[type="checkbox"] {
  margin-right: 10px;
}

select {
  padding: 8px;
  border-radius: 5px;
  background-color: #555;
  color: #fff;
  border: none;
}

select:hover {
  background-color: #666;
}

.pagination-info {
  color: #fff;
}

.color-filters input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  top: 30px; /* Adjust based on input position */

  z-index: 10;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.engine-health-container {
  display: inline-block;
  position: relative;
  text-align: center;
  bottom: 105px;

  cursor: pointer;
  z-index: 99;
}

.engine-light {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
  animation: pulse_engine 2s infinite ease-in-out;
}

.engine-icon {
  width: 73%;
  height: 73%;
}

.engine-info {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 10px;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  width: 50vw;
  z-index: 100; /* Ensure it's on top */
}

.engine-light:hover + .engine-info,
.engine-health-container:hover .engine-info {
  opacity: 1;
  visibility: visible;
}

.engine-info.visible {
  opacity: 1;
  visibility: visible;
}

.engine-status {
  font-size: 1rem;
  font-weight: bold;
}

.engine-metric {
  margin-top: 5px;
  font-size: 0.8rem;
  opacity: 0.8;
}

.engine-metric b {
  color: var(--button-hover-color);
}

/* Pulse Animation */
@keyframes pulse_engine {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.body-no-scroll {
  overflow: hidden;
  width: 100%;
  touch-action: none; /* Prevent scrolling on mobile */
  overscroll-behavior: contain; /* Stop scrolling from affecting parent elements */
}

.fade {
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes holographicBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.holographic {
  background: linear-gradient(
    45deg,
    rgba(26, 32, 44, 0.8),
    #254147,
    #3d5a80,
    #293241,
    #485563,
    #1f4068,
    #254147
  );
  animation: holographicBackground 10s ease infinite;
}

@keyframes subtleGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.common-gradient {
  background: linear-gradient(
    45deg,
    #1a202c,
    /* very dark blue-gray */ #254147 /* dark teal */
  );

  animation: subtleGradient 15s ease infinite;
}

.tutorial-modal-content {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #293241;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: slide-down 0.3s ease-out;
}

.tutorial-modal-close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #485563;
}

.tutorial-modal-header {
  font-size: 1.4em;
  margin-top: 0;
  color: #3d5a80;
}

.tutorial-modal-steps,
.tutorial-modal-explore {
  font-size: 1em;
  color: #485563;
}

.tutorial-modal-steps {
  padding-left: 20px;
}

.tutorial-modal-step {
  margin-bottom: 10px;
}

.tutorial-modal-explore {
  margin-top: 10px;
  font-style: italic;
  font-weight: 600;
}

/* Support Ticket Section */
.tutorial-modal-ticket {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #e0fbfc;
}

/* Registration Modal Container */
.registration-modal {
  background: rgba(37, 65, 71, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2.5rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(163, 126, 44, 0.3);
  color: #ffffff;
  margin: auto;
  margin-bottom: 15px;
}

/* Invitation Heading */
.invitation-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Registration Heading */
.registration-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Verification Heading */
.verification-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Password Heading */
.password-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Welcome Heading */
.welcome-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Invitation Form */
.invitation-form {
  display: flex;
  flex-direction: column;
}

/* Registration Form */
.registration-form {
  display: flex;
  flex-direction: column;
}

/* Verification Form */
.verification-form {
  display: flex;
  flex-direction: column;
}

/* Password Form */
.password-form {
  display: flex;
  flex-direction: column;
}

/* Invitation Label */
.invitation-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Registration Label */
.registration-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Verification Label */
.verification-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Password Label */
.password-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Invitation Input */
.invitation-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Registration Input */
.registration-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Verification Input */
.verification-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Password Input */
.password-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Invitation Submit Button */
.invitation-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Registration Submit Button */
.registration-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Verification Submit Button */
.verification-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Password Submit Button */
.password-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Registration Error Message */
.registration-error {
  color: #ff6b6b;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
}

/* Welcome Text */
.welcome-text {
  text-align: center;
  font-weight: 300;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

/* Welcome Highlight */
.welcome-highlight {
  font-weight: 600;
}

/* Get Started Button */
.get-started-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

/* Watchlist Highlight Button Special */
.watchlist-highlight-button-special {
  /* Add any additional styles specific to this button if needed */
}

/* Common Input Styles */
.registration-input,
.invitation-input,
.verification-input,
.password-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Common Label Styles */
.invitation-label,
.registration-label,
.verification-label,
.password-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Common Button Styles */
.invitation-submit-button,
.registration-submit-button,
.verification-submit-button,
.password-submit-button,
.get-started-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Hover Effects (Optional) */
.invitation-submit-button:hover,
.registration-submit-button:hover,
.verification-submit-button:hover,
.password-submit-button:hover,
.get-started-button:hover {
  opacity: 0.9;
}

/* Login Modal Container */
.login-modal {
  background: rgba(37, 65, 71, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2.5rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(163, 126, 44, 0.3);
  color: #ffffff;
  margin: auto;
  margin-top: 10%;
}

/* Login Heading */
.login-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Login Form */
.login-form {
  display: flex;
  flex-direction: column;
}

/* Login Label */
.login-label {
  margin-bottom: 0.5rem;
  color: #a37e2c;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Login Input Fields */
.login-input {
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Login Submit Button */
.login-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Forgot Password Button */
.forgot-password-button {
  padding: 0.4rem;
  margin-top: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  background: transparent;
}

/* Login Error Message */
.login-error {
  color: #ff6b6b;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
}

/* Forgot Password Form */
.forgot-password-form {
  display: flex;
  flex-direction: column;
}

/* Forgot Password Heading */
.forgot-password-heading {
  color: #a37e2c;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

/* Forgot Password Input Field */
.forgot-password-input {
  padding: 0.75rem;
  margin-bottom: 15px;
  border: 1px solid rgba(163, 126, 44, 0.3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
}

/* Forgot Password Submit Button */
.forgot-password-submit-button {
  padding: 0.75rem;
  background: linear-gradient(45deg, #a37e2c, #d4af37);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

/* Reset Message */
.reset-message {
  color: #ffffff;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

/* Back to Login Button */
.back-to-login-button {
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  background: transparent;
}

.ticket-header {
  font-size: 1.2em;
  color: #254147;
}

.ticket-comment-box {
  width: 80%;
  height: 60px;
  padding: 8px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #3d5a80;
  font-size: 1em;
  color: #485563;
  background-color: #f0f4f8;
  resize: none;
}

.ticket-submit-button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #3d5a80;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ticket-submit-button:hover {
  background-color: #254147;
}

/* input for filter manual in leads */
.quick-search-container {
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid rgba(255, 215, 0, 0.3);
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.quick-search-input {
  width: 80%;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 215, 0, 0.3);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  margin: auto;
}

.quick-search-input:focus {
  outline: none;
  border-color: rgba(255, 215, 0, 0.5);
}

.search-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  justify-content: center;
}

.search-tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(212, 160, 23, 0.15);
  border: 1px solid #d4a017;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  transition: all 0.2s ease;
}

.tag-edit-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 6px;
  padding: 0 4px;
  cursor: pointer;
  font-size: 12px;
}

.tag-edit-btn:hover {
  color: #d4a017;
}

.search-tag:hover {
  background: rgba(212, 160, 23, 0.25);
  border-color: #d4a017;
}

.manual-tag {
  background: rgba(33, 124, 95, 0.15) !important;
  border: 1px solid #217c5f !important;
}

.manual-tag-plus {
  margin-right: 6px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.tag-remove-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 6px;
  padding: 0 4px;
  cursor: pointer;
  font-size: 14px;
}

.tag-remove-btn:hover {
  color: #ff4444;
}

.tutorial-modal-minimized-button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease-in;
  position: fixed;
  right: 0;
  top: 20px;
  margin-right: 0.7rem;
  z-index: 2;
}

.tutorial-modal-wrapper.minimized {
  bottom: 20px;
  right: 20px;
  height: auto;
  margin-right: 0.7rem;
}

.tutorial-modal-description-button::after {
  content: "Help";
}

@keyframes slide-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Existing Transition for Smooth Background Color Change */
.watch-slot {
  transition: background-color 0.5s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

/* Quicker Pulse/Flash Animation (~1 second duration) */
@keyframes pulse-flash {
  0% {
    background-color: rgba(255, 255, 255, 0.8); /* Initial color */
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
    transform: scale(1);
  }
  30% {
    background-color: rgba(0, 255, 0, 0.7); /* Bright flash */
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.8);
    transform: scale(1.1); /* Small pulse */
  }
  60% {
    background-color: rgba(0, 255, 0, 0.5); /* Slight dimming */
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.6);
    transform: scale(1.05); /* Recede slightly */
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8); /* Return to original */
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
    transform: scale(1);
  }
}

/* Apply the Faster Combined Animation */
.watch-slot.animate-update {
  animation: pulse-flash 1s ease-in-out forwards;
}

/* Maintain your original .watch-slot styles */
.watch-slot {
  width: 60px;
  height: 60px;
  border: 1.5px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  position: relative;
  background-color: #1c1c1c;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out, background-color 0.5s ease,
    box-shadow 0.3s ease;
}

.locked-slot {
  width: 60px;
  height: 60px;
  border: 1.5px dashed #ccc;
  background-color: #1c1c1c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 0 5px;
  justify-content: center;
  transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
}

.filled-slot {
  border: 1.5px solid #ccc;
}

.locked-slot:hover {
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.7) !important;
}

/* Optional: Hover Effects for Better Interactivity */
.watch-slot:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.7);
}

.latest-leads {
  display: flex;
  flex-direction: row;
  padding: 1em;
  gap: 1em;
  width: 45%;
  background-color: transparent;
  white-space: nowrap;
  scroll-behavior: smooth;
  margin: auto;
  justify-content: center;

  position: relative; /* Important for absolute positioning of children */
}

.hover-details {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5em;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  white-space: normal;
  z-index: 10;
  color: #fff;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
}

.lead-segment-text {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5em;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  white-space: normal;
  z-index: 1;
  color: #fff;
  width: 250px;
  font-size: 1.2rem;
}

.modal-content-start-autowatch {
  background-color: #0f181a;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  margin-bottom: 90px;
  box-shadow: 0 2px 10px rgba(212, 175, 55, 0.3);
}

.lead-feed-info {
  font-size: 0.8em;
}

.session-expires-text {
  font-size: 11px;
  margin-top: -20px;

  position: absolute;
  left: 4px;
  z-index: 2;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.6rem;
  bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .toggle-btn {
    font-size: 9px;
  }
  .toggle-btn-sessions {
    font-size: 8px;
  }

  .no-results-found-pic {
    margin-top: 40% !important;
    width: 200px !important;
  }

  .start-btn,
  .stop-btn {
    font-size: 0.5rem;
    letter-spacing: 0px;
  }

  .lead-feed-info {
    font-size: 0.75em;
  }

  .core-ref {
    font-size: 0.55rem;
  }

  .modal-content-start-autowatch {
    width: 70vw;
    height: 90vh;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .login-modal {
    width: 70%;
  }

  .login-input::placeholder {
    font-size: 12px;
  }

  .login-modal h2 {
    font-size: 1.35rem;
  }

  .registration-modal h2 {
    font-size: 1.35rem;
  }

  .registration-modal {
    width: 70%;
  }

  .registration-input::placeholder {
    font-size: 12px;
  }

  .contact-info-pic {
    display: none;
  }

  .contact-info {
    font-size: 0.7rem;
  }

  .engine-health-container {
    bottom: 117px;
  }

  .logo-container {
    margin-bottom: 33px;
    width: 120px; /* Match your original logo size */
    height: 120px; /* Ensure the aspect ratio matches */
    min-width: 120px; /* Prevent shrinking */
    min-height: 120px; /* Prevent shrinking */
  }

  /* Styles for phones */
  .tabs-container .fire-icon,
  .tabs-container .limited-icon,
  .tabs-container .stale-icon {
    display: none;
  }

  .lead-image {
    display: none;
  }

  .search-tag {
    padding: 2px 4px;

    font-size: 10px;
  }

  .modal-content {
    width: 95vw;
    height: 87vh;
  }

  .table-container {
    font-size: 12px;
  }

  .watchlist-container {
    width: 90%;
    gap: 15px;
  }

  .suggestion-dropdown {
    font-size: 16px;
  }

  .watch-card {
    width: 80vw;
  }

  .adjust-margin {
    margin-top: -20px !important;
  }

  .special-watch-card {
    margin-top: -35px;
  }

  .disclaimer {
    font-size: 0.55rem;
  }

  .tab-button {
    font-size: 0.85rem;
    display: flex;
  }

  .latest-leads {
    width: 95%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 1em;
    scroll-behavior: smooth;
    justify-content: flex-start;
  }

  .hover-details {
    position: fixed; /* Override absolute positioning */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 300px;
    z-index: 1000;
    max-height: 22vh;
    overflow-y: auto;
  }

  .lead-segment-text {
    position: fixed; /* Override absolute positioning */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 300px;
    z-index: 999;
    max-height: 22vh;
    overflow-y: auto;
  }

  .tutorial-modal-description-button:after {
    content: "Help";
    font-size: 0.7rem;
  }

  .autowatch-button::after {
    content: "About"; /* Hides the text but keeps the button visible */
    font-size: 0.7rem;
  }

  .chat-button::after {
    content: "Chat";
    font-size: 0.7rem;
  }

  .goto-account-button::after {
    content: "Account";
    font-size: 0.7rem;
  }

  .goto-engine-button::after {
    content: "App →";
  }

  /* Base alert styling */
  .alert {
    font-size: 16px;
  }

  .reference-search-input::placeholder {
    font-size: 12px; /* Smaller placeholder text */
  }

  .common-gradient {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .main-header {
    font-weight: 700;
    font-size: 3rem;
    margin-top: -40px;
  }

  .subheader {
    opacity: 0; /* Start invisible */
    transition: opacity 1s; /* Smooth transition */
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .header-text {
    font-size: 15px;
    margin: 40px auto;
    width: 90%;
  }

  .front-page-button {
    padding: 0.7rem 0.5rem !important;
    font-size: 0.7rem !important;
  }

  .header-subtext {
    font-style: italic;
    margin: auto;
    width: 90%;
  }

  .iceberg {
    margin-bottom: 200px;
  }

  .autowatch-depth-finder {
    top: -200px;
    left: 0px;
    right: 0px;
  }

  .autowatch-decoder {
    top: 610px;
    left: 10px;
    height: 190px;
    margin-top: 40px;
  }

  .autowatch-depth-finder h2 {
    font-size: 12px;
  }

  .autowatch-depth-finder p {
    font-size: 11px;
  }

  .autowatch-decoder2 {
    font-size: 12px;
  }

  .autowatch-decoder {
    font-size: 13px;
  }

  .header-subtext2 {
    margin: auto;
    width: 90%;
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 1.2rem;
  }

  .header-subtext3 {
    margin: auto;
    width: 70%;
    margin-bottom: 25px;
    margin-top: 255px;
    font-size: 1.2rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Styles for small devices */
  .common-gradient {
    flex: 1 1 calc(50% - 20px);
  }

  .autowatch-depth-finder {
    top: -400px;
    right: 165px;
    font-size: 13px;
  }

  .autowatch-depth-finder p {
    font-size: 12px;
  }

  .lead-feed-info {
    font-size: 0.8em;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Styles for tablets */

  .lead-feed-info {
    font-size: 0.85em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Styles for desktops */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Styles for large desktops */
}
